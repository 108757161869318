import styled from "styled-components";
import { Container, Row } from "react-bootstrap";

export const ContactUsContainer = styled(Container)`
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const Inquiries = styled.div`
  padding: 0 10%;
  position: relative;
  width: 100%;

  @media only screen and (max-width: 426px) {
    padding: 0 5%;
  }
`;

export const ContactUsBg = styled.div`
  position: relative;
  width: 100%;
  height: 575px;
  background: url(${(props) => props.bg || null}) no-repeat;
  background-position: 15% center;
  background-size: cover;
  z-index: 2;

  @media only screen and (max-width: 769px) {
    height: 470px;
    background-position: 50% center;
    transform: scale(1);
  }

  @media only screen and (max-width: 426px) {
  }
`;

export const StyledRow = styled.div`
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  gap: 0;
  


  @media screen and (min-width: 600px) {
    flex-direction: column;
  }
`;

export const ContactUsRow = styled(Row)`
  position: absolute;
  display: block;
  bottom: -80px;
  margin-right: auto;
  margin-left: auto;
  z-index: 5;
  width: 80%;
  background: #fff;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  display: inline-flex;
  justify-content: space-between;
  

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
   
  }

  h1,
  svg {
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 1440px) {
    .office-address {
      max-width: 390px;
    }

    .contact-info {
      width: fit-content;
    }
  }

  @media only screen and (max-width: 1365px) {
    flex-direction: column;

    .office-address,
    .contact-info {
      margin-bottom: 25px;
    }

    .col-lg-6,
    .col-lg-3 {
      min-width: 100%;
    }

    .office-map {
      padding: 0;

      a {
        width: 100%;
        height: 300px;
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    bottom: 0;
  }
`;
export const MapRow = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  @media only screen and (max-width: 426px) {
    padding: 0 5%;
  }
`;
