import React from "react"
import { Col } from "react-bootstrap"
import { getImage } from "gatsby-plugin-image"
import InquiriesForm from "./InquiriesForm"
import BgContactUs from "../../multimedia/building-contact-us.png"
import { graphql, useStaticQuery } from "gatsby"
import { Heading32, Paragraph18 } from "../../styles/PageStyles"
import {
  ContactUsContainer,
  Inquiries,
  ContactUsBg,
  ContactUsRow,
  MapRow,
  StyledRow,
} from "./ContactUsContent.styled"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import { BsFillArrowUpCircleFill } from "react-icons/bs"

const ContactUsContentId = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "contacts" } }) {
        frontmatter {
          businessInqBg {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          generalInqBg {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          connect {
            title
            address {
              line
            }
            tel
            fax
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { connect } = frontmatter
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBaaDlpmBjveTMHKoxfAvmatlfIZIQOmrs", // ,
    // ...otherOptions
  })
  const isBrowser = typeof window != "undefined"
  const mapTypePosition = isBrowser
    ? window?.google?.maps.ControlPosition.LEFT_BOTTOM
    : null

  const options = {
    id: "6be31dddf1b16cea",
    mapTypeControlOptions: {
      position: mapTypePosition, // 'right-center' ,
      // ...otherOptions
    },
  }

  return (
    <ContactUsContainer fluid>
      <Inquiries>
        <div id="business-inquiries">
          <InquiriesForm
            name="Business Inquiries"
            image={getImage(frontmatter?.businessInqBg)}
          />
        </div>
        <div id="general-inquiries">
          <InquiriesForm
            name="General Inquiries"
            image={getImage(frontmatter?.generalInqBg)}
          />
        </div>
      </Inquiries>

      <StyledRow>
        <ContactUsRow>
          <Col className="office-address" lg={12}>
            <Heading32>{connect?.title}</Heading32>
            {connect?.address.map((item, index) => (
              <Paragraph18 key={index}>{item?.line}</Paragraph18>
            ))}
          </Col>
          <Col className="contact-info pt-20" lg={12}>
            <Paragraph18>{`Tel.: ${connect?.tel}`}</Paragraph18>
            <Paragraph18>{`Fax.: ${connect?.fax}`}</Paragraph18>
          </Col>
        </ContactUsRow>
        <ContactUsBg id="connect" bg={BgContactUs}></ContactUsBg>
      </StyledRow>

      <MapRow>
        {isLoaded && (
          <GoogleMap
            options={options}
            mapContainerStyle={{ width: "100%", height: "70vh" }}
            center={{ lat: 3.161568677719078, lng: 101.69576181632786 }}
            zoom={19}
            id="6be31dddf1b16cea"
          >
            <Marker
              color="blue"
              icon={<BsFillArrowUpCircleFill />}
              position={{ lat: 3.161568677719078, lng: 101.69576181632786 }}
            />
          </GoogleMap>
        )}
      </MapRow>
    </ContactUsContainer>
  )
}

export default ContactUsContentId
