import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/shared/Layout"
import PageHeader from "../components/shared/PageHeader"
import ContactUsContent from "../components/contact-us/ContactUsContent"

const ContactUs = ({ data }) => {
  return (
    <Layout seoTitle="Contacts" seoKeywords="Contact JFEM">
      <PageHeader
        pageTitle="Contacts"
        pageDescription={data?.markdownRemark?.frontmatter?.header?.description}
      />
      <ContactUsContent />
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "contacts" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
  }
`
